
import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      locale: this.$i18n.locale,
    };
  },
  methods: {
    switchLang(code: string): void {
      this.$i18n.setLocale(code);
    },
  },
});
